import { Component, OnInit } from '@angular/core';
import { AppService } from 'src/app/app.service';
import { CartService } from 'src/app/Lazy-loaded-Modules/Cart-module/cart-page/cart-page.service';

import {Router} from '@angular/router';
import * as moment from 'moment';
import { DatePipe } from '@angular/common';
import {ENDPOINTS, ORG_DETAILS} from 'src/app/app.config';
import {config} from 'src/configs/config';
import { CartNewService } from '../../Lazy-loaded-Modules/Cart-module/cart-new/cart-new.service';
import { LocalStorageService } from 'src/app/Services/auth-service/local-storage.service';
import { CustomerLoginStorageService } from "src/app/Lazy-loaded-Modules/Checkout-new/checkout-login/customer-login-storage.service";
import { LoginService } from 'src/app/Lazy-loaded-Modules/Account-Modules/login-new/login-new.service';
import { CheckoutLocalStorageService } from 'src/app/Lazy-loaded-Modules/Checkout-new/checkout-local-storage.service';
@Component({
  selector: 'app-mini-cart',
  templateUrl: './mini-cart.component.html'
})
export class MiniCartComponent implements OnInit {
  CONFIG = config;
  cartData:any = [];
  approved:any;

  constructor(public appService : AppService,private cartService : CartService,private datePipe: DatePipe,private router : Router, private cartNewService: CartNewService, private localService: LocalStorageService, private customerLoginStorageService: CustomerLoginStorageService,private LoginService:LoginService,private CheckoutLocalStorageService:CheckoutLocalStorageService) { }

  ngOnInit(): void {
   

    if(this.cartNewService.getCartItems() !== null){
      this.cartData = this.cartNewService.getCartItems();
     
      if( this.router.url.includes('/confirmation'))
      {
        
       //this.cartData.splice(0,this.cartData.length)

          this.removeallcartItem(this.cartData.length)
        
        
      }
    }
    this.cartNewService.currentData.subscribe(
      currentData => {
        let curData:any = currentData;
        if(curData.type === "cartData"){
          this.cartData = curData.value;
        }
      }
    )
  }

  removeItem(index)
  {
    this.appService.cartProducts.results.splice(index,1);
    this.cartService.updateCartItem(this.appService.cartProducts.results);
  }
  removeSubItem(i,j)
  {
    console.log(this.appService.cartProducts.results)
    this.appService.cartProducts.results[i].subcart.splice(j,1)
    this.appService.cartProducts.results[i].subquantity.splice(j,1)
    
    // this.appService.cartProducts.results.splice(i,1);
    this.appService.totalCartItems = this.cartService.totalCartItems();
    if(this.appService.cartProducts.results[i].subcart.length==0){
      this.appService.cartProducts.results.splice(i,1)
    }
   // this.calculateTotal();
    this.cartService.updateCartItem(this.appService.cartProducts.results);
    // this.loadExistingCart()
  }
  logout()
  {
    this.appService.userLoggedIn = false;
  this.appService.userName = "";
  console.log( this.appService.userName,"login user")

  if(this.localService.get('UserId')!=null)
  {
    
  this.LoginService.updateAdminFormEvent({name: "alert_error"});
  }
  else{
    this.LoginService.updateAdminFormEvent({name: "ask_login"});
  }
  this.appService.loggedin_user = false;
  this.localService.remove('accessToken');
  this.localService.remove('refreshToken');
  this.localService.remove('expireTime');
  this.localService.remove('UserName');
  this.localService.remove('UserId');
  this.localService.remove('customerGroup');
  this.localService.remove('domainName');
  this.localService.remove('rexpireTime');
  this.localService.remove('AiemaUserName');
  this.localService.remove('couponCode');
  this.localService.remove('company_name');
  this.localService.remove('gst_number');
  this.localService.remove('company_address');
  this.appService.removeSession();
  this.customerLoginStorageService.removeCustomer();
  this.CheckoutLocalStorageService.clearDataonLogout();
    this.CheckoutLocalStorageService.removeOrderId();

  
  
  this.router.navigate([`/login-new`], { skipLocationChange: false });

  }
  async  getProfileDetails(id) {
    return new Promise((resolve, reject) => {
      let requestBody = {
        "domain_name": ORG_DETAILS.DOMAIN_NAME,
        "user_id": ORG_DETAILS.USER_ID,
        "extras": {
          "find": {
            "id" : id
          }
        }
      }
      // MAKE A SERVICE CALL HERE...
      this.appService.postApiCall(requestBody,ENDPOINTS.GET_CUSTOMER,).subscribe(
        resp => {
          if (resp) {
         
            this.approved= resp.result.data[0]!=null?resp.result.data[0].status:'';
            
           
         
          }
          else{
            this.approved="";
          }
          resolve(true);
        },
        err => {
         resolve(false);
          // if (err.error.statusCode === 403) {
          //   this.alertService.error('Session Time Out! Please login Again', this.options)
          //   this.router.navigate([`/login-new`], { skipLocationChange: false });
          // }
          // else if (err.error.message) {
          //   this.alertService.error(err.error.message, this.options)
          // }
          // else {
          //   this.alertService.error('Something bad happened. Please try again!', this.options);
          // }
        }
      )
    })
   
  }
  async goToCheckout() {
    await  this.getProfileDetails(this.localService.get('UserId')!=null?Number(this.localService.get('UserId')):'');

   if(String(this.approved)!=='Approved'&&ORG_DETAILS.NAME === "Karupattiraja")
    {
      this.logout();
    }
    else{
      let requestData = {
        "domain_name": ORG_DETAILS.DOMAIN_NAME,
        "user_id": ORG_DETAILS.USER_ID,
        "extras": {
          "find": {}
        }
      }
  
      this.appService.postApiCall(requestData, ENDPOINTS.ACCEPTANCE_TIMINGS).subscribe(
        resp => {
          if (resp.success === 1 && resp.result.data.length > 0) {
  
            var today = moment();
  
            var acceptanceTiming = resp.result.data[0].order_acceptance_timing;
  
            var timeAvailable: boolean;
  
            if (acceptanceTiming.length === 0) {
              this.router.navigate(['/checkout-new'])
              return
            }
            else {
              acceptanceTiming.forEach(element => {
                let from = moment(today.format('YYYY-MM-DD') + " " + element.from,"YYYY-MM-DD hh:mm A")
                let to = moment(today.format('YYYY-MM-DD') + " " + element.to,"YYYY-MM-DD hh:mm A")
  
                if (today >= from && today <= to) {
                  timeAvailable = true
                  return
                }
              });
            }
            if (timeAvailable === true) {
              this.router.navigate(['/checkout-new'])
            }
            else {
              if("CONFIG.title === 'Nesma'"){
                this.router.navigate(['/cart-new'],{ state: { shop: false } })
              }
              else{
                this.router.navigate(['/cart'],{ state: { shop: false } })
              }
              
            }
  
          }
          else
          {
            if("CONFIG.title === 'Nesma'"){
              this.router.navigate(['/cart-new'])
            }
            else{
              this.router.navigate(['/cart'])
            }
            
          }
        },
        err => {
          if("CONFIG.title === 'Nesma'"){
            this.router.navigate(['/cart-new'])
          }
          else{
            this.router.navigate(['/cart'])
          }
        })

    }
    
  }

  removecartItem(index){
    this.cartData.splice(index,1);
    this.cartNewService.updateToCart(this.cartData);
  }
  removeallcartItem(length){
    this.cartData.splice(0,length);
    this.cartNewService.updateToCart(this.cartData);
  }

}
